import {getCmsReports, getVendorPartners} from '../actions/vpms'

const {createSlice} = require('@reduxjs/toolkit')

const initialState = {
  reports: {},
  totalPages: 1,
  partners: [],
  isLoading: false,
}

const vpms = createSlice({
  name: 'vpms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCmsReports?.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getCmsReports?.fulfilled, (state, action) => {
      state.isLoading = false
      state.reports = action?.payload
    })
    builder.addCase(getCmsReports?.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(getVendorPartners?.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getVendorPartners?.fulfilled, (state, action) => {
      console.log(action?.payload?.total_pages, 'vdsnkjo')
      state.isLoading = false
      state.partners = action?.payload?.data || []
      console.log( state.partners,'555555555555555555555555555555555555555555******************')
      state.totalPages = action?.payload?.total_pages || 1
    })
    builder.addCase(getVendorPartners?.rejected, (state, action) => {
      state.isLoading = false
    })
  },
})
export default vpms.reducer
