import {
  getCareTypes,
  getPackageCategory,
  getPackageDetails,
  getPackageList,
  getProductCategories,
  getProducts,
  getCustompacakgelist,
  getalluser,getVariations
} from '../../actions/catalogs'

const {createSlice} = require('@reduxjs/toolkit')

const initialState = {
  packageList: [],
  variations:[],
  customList: [],
  details: {},
  custompackagelist:[],
  products: [],
  careTypes: [],
  productCategories: [],
  careCategories: [],
  packageCategory: [],
  getall_user:[],
  isLoading: false,
}

const catalogs = createSlice({
  name: 'catalogs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPackageList?.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(getPackageList?.fulfilled, (state, action) => {
      state.isLoading = false
      state.total_pages = action?.payload?.data?.pagination?.total_pages
      state.packageList = action?.payload.data?.packages || []
      console.log('bdvbvjb', state.total_pages)
      // state.customList = action?.payload?.custom_packages;
    })
    builder.addCase(getPackageList?.rejected, (state, action) => {
      state.isLoading = false
    })
   



    builder.addCase(getCustompacakgelist?.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(getCustompacakgelist?.fulfilled, (state, action) => {
      state.isLoading = false
      state.total_pages = action?.payload?.data?.pagination?.total_pages
      state.custompackagelist = action?.payload.data?.custom_packages || []
      console.log('ooooooooooooooooooooo', state.total_pages)
    })
    builder.addCase(getCustompacakgelist?.rejected, (state, action) => {
      state.isLoading = false
    })



    builder.addCase(getalluser?.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(getalluser?.fulfilled, (state, action) => {
      state.isLoading = false
      state.getall_user =  action?.payload?.data||[]
      console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', state.getall_user)
    })
    builder.addCase(getalluser?.rejected, (state, action) => {
      state.isLoading = false
    })




    builder.addCase(getPackageDetails?.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(getPackageDetails?.fulfilled, (state, action) => {
      state.isLoading = false
      state.details = action?.payload
      // state.customList = action?.payload?.custom_packages;
    })
    builder.addCase(getPackageDetails?.rejected, (state, action) => {
      state.isLoading = false
    })



    builder.addCase(getCareTypes?.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(getCareTypes?.fulfilled, (state, action) => {
      state.isLoading = false
      state.careTypes = action?.payload
      // state.customList = action?.payload?.custom_packages;
    })
    builder.addCase(getCareTypes?.rejected, (state, action) => {
      state.isLoading = false
    })

    builder.addCase(getPackageCategory?.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(getPackageCategory?.fulfilled, (state, action) => {
      state.isLoading = false
      state.packageCategory = action?.payload.data || []
      // state.customList = action?.payload?.custom_packages;
    })
    builder.addCase(getPackageCategory?.rejected, (state, action) => {
      state.isLoading = false
    })
    


    builder.addCase(getProducts?.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(getProducts?.fulfilled, (state, action) => {
      state.isLoading = false
      state.total_pages = action?.payload?.data?.data?.pagination?.total_pages
      state.products = action?.payload?.data?.data?.products?.map((item) => item?.product)?.flat() || []
      state.products = action?.payload?.data?.data?.products

      console.log( state.total_pages,'99999999999999)')
      console.log( state.products,'-----------------)')

    })
    builder.addCase(getProducts?.rejected, (state, action) => {
      state.isLoading = false
    })




    builder.addCase(getVariations?.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(getVariations?.fulfilled, (state, action) => {
      state.isLoading = false
      state.total_pages = action?.payload?.totalpages
      state.variations = action?.payload?.data|| []
    })
    builder.addCase(getVariations?.rejected, (state, action) => {
      state.isLoading = false
    })




    builder.addCase(getProductCategories?.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getProductCategories?.fulfilled, (state, action) => {
      state.isLoading = false
      state.productCategories = action?.payload?.data?.categories
      state.total_pages = action?.payload?.data?.pagination?.total_pages
      console.log(state.productCategories,'6666666666666')
      console.log(state.total_pages,'9)9)))))9')

      // state.customList = action?.payload?.custom_packages;
    })
    builder.addCase(getProductCategories?.rejected, (state, action) => {
      state.isLoading = false
    })
  },
})

export default catalogs.reducer
