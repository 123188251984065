import {createAsyncThunk} from '@reduxjs/toolkit'
import {SET_PASSWORD, SIGN_UP_DISCOVER, USER_DETAILS, USER_LIST} from '../../../axios/services'
import {API_ERROR} from '../../../global'
import {toast as message} from 'react-toastify'
import {deleteApi, getApi, patchApi, postApi} from '../../../axios'

export const getUsersList = createAsyncThunk(
  'users/getUsersList',
  async (payload = {}, thunkAPI) => {
    try {
      const response = await getApi(
        `${USER_LIST}?page=${payload?.page || 1}&${
          payload?.user_role ? `user_role=${payload?.user_role}&` : ''
        }items_per_page=10&first_name=${payload?.first_name || ''}`
      )
      console.log(response?.data, 'sdnlvskhdln')
      return response?.data?.data
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const postCustomerSignUp = async ({body, next}) => {
  try {
    const response = await postApi(`${SIGN_UP_DISCOVER}nursefam`, {
      ...body,
      user_role: 'nursefam',
    })
    if (response?.data?.success) {
      next()
      message.success(response?.data?.message)
    }
    console.log('bsbbcjsbj', response?.data)
    return response?.data
  } catch (error) {
    message.error(error?.response?.data?.message || API_ERROR)
  }
}

export const postHealonSignUp = async ({body, next}) => {
  console.log(body,'666666666666')
  try {
    const response = await postApi(`${SIGN_UP_DISCOVER}healon`, {
      ...body,
      user_role: 'healon',
    })
    if (response?.data?.success) {
      next()
      message.success(response?.data?.message)
    }
    return response?.data
  } catch (error) {
    message.error(error?.response?.data?.message || API_ERROR)
  }
}


export const postMultipleRolesSignUp = async ({body, next}) => {
  console.log(body,'666666666666666')
  try {
    const response = await postApi(`${SIGN_UP_DISCOVER}`, {
      ...body,
    })
    if (response?.data?.success) {
      next()
      message.success(response?.data?.message)
    }
    return response?.data
  } catch (error) {
    message.error(error?.response?.data?.message || API_ERROR)
  }
}

export const postVendorSignUp = async ({body, next}) => {
  try {
    const response = await postApi(`${SIGN_UP_DISCOVER}seller_vp`, {
      ...body,
      user_role: 'seller_vp',
    })
    if (response?.data?.success) {
      next()
      message.success(response?.data?.message)
    }
    return response?.data
  } catch (error) {
    message.error(error?.response?.data?.message || API_ERROR)
  }
}

export const postPartnerSignUp = async ({body, next}) => {
  try {
    const response = await postApi(`partner/partner-signup`, body)
    if (response?.data?.success) {
      next()
      message.success(response?.data?.message)
    }
    return response?.data
  } catch (error) {
    message.error(error?.response?.data?.message || API_ERROR)
  }
}

export const postNurseSignUp = async ({body, next}) => {
  console.log(body,'96969696969696969')
  try {
    const response = await postApi('/signup/', body)

    if (response.data?.success) {
      next()
      return response.data
    } else {
      message.error(response.data?.message)
    }
  } catch (e) {
    console.log(e, 'vdknlvs')
    message.error(e.response.data?.message)
  }
}

export const postEmployerSignup = async ({body, next}) => {
  try {
    const response = await postApi('/employer/signup/', body)
    if (response.data?.success) {
      next()
      return response.data
    } else {
      message.error(response.data?.message)
    }
  } catch (error) {
    message.error(error?.response?.data?.message || 'Something went wrong!')
    return error
  }
}

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async ({payload, next}, thunkAPI) => {
    try {
      const response = await deleteApi(`${USER_LIST}?user_id=${JSON.stringify(payload)}`)
      if (response.data?.success) {
        next()
        message.success(response.data?.message)
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const patchUserListDetail = createAsyncThunk(
  'users/patchUserListDetail',
  async ({body, next}, thunkAPI) => {
    try {
      const response = await patchApi(`${USER_LIST}`, body)
      if (response.data?.success) {
        next(response.data?.data)
        message.success(response.data?.message)
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const getUserDetails = createAsyncThunk('user/getUserDetails', async (payload, thunkAPI) => {
  try {
    const response = await getApi(USER_DETAILS + 'superuser')
    if (response?.data?.success) {
      return response?.data?.data
    }
  } catch (error) {
    message.error(error?.response?.data?.message || API_ERROR)
    return thunkAPI.rejectWithValue(error.response.data.message)
  }
})
export const patchUserDetails = createAsyncThunk(
  'user/patchUserDetails',
  async ({data, next = () => {}}, thunkAPI) => {
    try {
      const response = await patchApi(USER_DETAILS + 'superuser', data)
      if (response?.data?.success) {
        next()
        message.success(response?.data?.message)
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const patchUserPassword = createAsyncThunk(
  'user/patchUserPassword',
  async ({data, next = () => {}}, thunkAPI) => {
    try {
      const response = await patchApi(SET_PASSWORD, data)
      if (response?.data?.success) {
        next()
        message.success(response?.data?.message)
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)
